import Vue from "vue";

Vue.directive("focus", {
	/* Based on https://github.com/simplesmiler/vue-focus */
	inserted: function(el, binding) {
		if(/^#[^!].*/.test(document.location.hash)) { // allow in-page anchor navigation
			return;
		}
		if(binding.value) {
			el.focus();
		} else {
			el.blur();
		}
	},
	componentUpdated: function(el, binding) {
		if(binding.modifiers.lazy) {
			if(Boolean(binding.value) === Boolean(binding.oldValue)) {
				return;
			}
		}
		if(binding.value) {
			el.focus();
		} else {
			el.blur();
		}
	}
});
