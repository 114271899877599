import wpf from "wpf";
import Vue from "vue";
import { ignoredError } from "../core/trackJsErrors";
import { isString } from "./util";

// based on dev-only method Vue.util.formatComponentName
function formatComponentName(vm) {
	if (vm.$root === vm) {
		return '$root'
	}
	const name = vm._isVue
		? vm.$options.name || vm.$options._componentTag
		: vm.name;
	return (name ? '<' + name + '>' : 'anonymous component') +
		(vm._isVue && vm.$options.__file ? ' at ' + vm.$options.__file  : '')
}

/* Pass Vue errors to Google Analytics:
 - https://vuejs.org/v2/guide/deployment.html#Tracking-Runtime-Errors
 - https://vuejs.org/v2/api/#errorHandler
 - https://github.com/vuejs/vue/blob/dev/src/core/util/error.js */

if(process.env.NODE_ENV === "production") {
	Vue.config.errorHandler = function (err, vm, info) {
		const errMsg = err.toString();
		const errLoc = formatComponentName(vm)+" in "+info;
		if(typeof ga === 'function') { // Only if Google Analytics is available
			if(!ignoredError(errMsg)) {
				ga('send', 'event', 'Vue Error', errMsg, errLoc, {nonInteraction: true});
				wpf.debug("Sent to GA: "+errMsg+" @ "+errLoc);
			} else {
				wpf.debug("Not sent (filtered): "+errMsg+" @ "+errLoc);
			}
		} else {
			wpf.debug("Not sent (no GA): "+errMsg+" @ "+errLoc);
		}
		wpf.warn(errLoc, err);
	};
}

/* Mixin */

const Logging = {
	methods: {
		log() {
			if(arguments.length >= 1 && arguments[0] != null && isString(arguments[0])) {
				arguments[0] = "["+formatComponentName(this)+"] "+arguments[0];
			}
			wpf.log.apply(wpf, arguments);
		},
		debug() {
			if(wpf.cfg.debug) {
				this.log.apply(this, arguments);
			}
		},
		warn() {
			if(arguments.length >= 1 && arguments[0] != null && isString(arguments[0])) {
				arguments[0] = "["+formatComponentName(this)+"] "+arguments[0];
			}
			wpf.warn.apply(wpf, arguments);
		}
	}
};

Vue.mixin(Logging);

export default Logging;
