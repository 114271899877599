
export function ignoredError(msg) {
	return /adsbygoogle\.push.+(No slot size for availableWidth|No link unit size for width)/.test(msg);
}

export default function(wpf) {
	// https://davidwalsh.name/track-errors-google-analytics
	// http://stackoverflow.com/questions/21718481/report-for-exceptions-from-google-analytics-analytics-js-exception-tracking
	const originalOnError = typeof window.onerror === 'function' ? window.onerror : null;

	window.onerror = function(msg, url, line, col, errObj) {
		if(typeof ga === 'function') { // Only if Google Analytics is available
			// http://fronttalks.ru/events/2013-07/presentations/androsov/index.html?full#48
			let errMsg = msg;
			let errUrl = url;
			if(msg && typeof msg !== 'string') {
				if(msg.type && msg.target) {
					// http://fronttalks.ru/events/2013-07/presentations/androsov/index.html?full#47
					errMsg = 'Error loading script';
					try {
						errUrl = msg.target.src;
					} catch(e) { }
				}
			} else if(!!errObj && typeof errObj !== 'undefined' && typeof errObj.message !== 'undefined') {
				errMsg = errObj.message;
			}
			if(!!errUrl) {
				errUrl = errUrl.replace(/^(https?:\/\/[^\/]\/assets\/js\/advert.js)\?_=.+$/, "$1");
			}
			if((!errUrl || !!errUrl
					&& !/(miscellaneous|extension)_bindings/.test(errUrl)
					&& !/^chrome:/.test(errUrl)
					&& !/^moz-extension:/.test(errUrl)
					&& !/^file:/.test(errUrl)
					&&(
						!/(\.googlesyndication\.com|\.googleadservices\.com|\.googletagservices\.com|\.doubleclick\.net|adservice\.google\.[a-z]{2,3}|mc\.yandex\.ru|an\.yandex\.ru)/.test(errUrl)
						|| !/^Error loading script\.?/.test(errMsg)
					)
				)
				&& !/mc\.yandex\.ru\/metrika/.test(errUrl)
				&&(!/^Script error\.?/.test(errMsg) || wpf.cfg.trackCrossOriginErrors)
				&& !/Blocked a frame with origin.+from accessing/.test(errMsg)
				&& !ignoredError(errMsg)
			) {
				const errLoc = errUrl+" ("+(line || 0)+":"+(col || 0)+")";
				if(!wpf.cfg.debug) {
					ga('send', 'event', 'JS Error', errMsg, errLoc, { nonInteraction: true });
				}
				wpf.debug("Sent to GA: "+errMsg+" @ "+errLoc);
			} else {
				wpf.debug("Not sent (filtered): "+errMsg+" @ "+errUrl+" ("+line+":"+col+")");
			}
		} else {
			wpf.debug("Not sent (no GA): "+msg+" @ "+url+" ("+line+":"+col+")");
		}
		if(!originalOnError) {
			return false; // Let the default handler run
		} else {
			return originalOnError(msg, url, line, col, errObj);
		}
	};
}
