import wpf from "wpf";

function _notify(idOrMsg, status, icon, iconCls, timeout, group) {
	status = status ? status : "info";
	let msg = wpf.msg[idOrMsg];
	if(msg) {
		wpf.debug("Message ["+status+", "+idOrMsg+"]: "+msg);
	} else {
		msg = idOrMsg;
		wpf.debug("Message ["+status+"]: "+msg);
	}
	if(icon) {
		iconCls = iconCls || "";
		msg = `<div class='uk-nbfc'><i class=' uk-float-left uk-icon-large uk-icon-${icon} ${iconCls}'></i> <span class='uk-display-block uk-margin-large-left'>${msg}</span></div>`;
	}
	return UIkit.notify({
				message: msg,
				pos: "top-center",
				status: status,
				timeout: timeout >= 0 ? timeout : 7000,
				group: group || ("wpf-msgs-"+status)
			});
}

export default {
	show: function(msg, status, icon, timeout, group) {
		return _notify(msg, status, icon, "uk-margin-small-top", timeout, group);
	},
	error: function(msg) {
		return _notify(msg, "danger", "exclamation-triangle", "uk-margin-small-top", 0, null);
	},
	inProgress: function(msg) {
		return _notify(msg, "info", "spinner", "uk-icon-spin", 0, "wpf-msgs-in-progress");
	},
	hide: function(group) {
		wpf.debug("Hiding all notifications ...");
		UIkit.notify.closeAll(group);
	},
	hideInProgress: function() {
		wpf.debug("Hiding in-progress notifications ...");
		UIkit.notify.closeAll("wpf-msgs-in-progress");
	},
	blocking: function(msg) {
		wpf.debug("Blocking message: "+msg);
		return UIkit.modal.blockUI(msg);
	}
}
