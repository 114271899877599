import Vue from "vue";
import { getMsg } from "./util";

Vue.filter("msg", function(msgId) { return getMsg(msgId); });

Vue.filter("capitalize", function (value) { return value ? value[0].toUpperCase() + value.slice(1) : value; });

Vue.filter("prettyDate", function (strDt, dflt) {
	if(!strDt) {
		return dflt || "";
	} {
		const dt = new Date(strDt);
		return dt.toLocaleDateString("ru")+" "+dt.toLocaleTimeString("ru", {hour: '2-digit', minute:'2-digit'});
	}
});
