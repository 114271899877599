<template>
	<button type="button" @click="doAction"><slot></slot></button>
</template>
<script>
	import { redirect, redirectViaPost } from "./util";

	export default {
		props: {
			url: { type: String, required: true },
			method: { type: String, default: "GET" }
		},
		methods: {
			doAction(ev) {
				ev.preventDefault();
				this.debug((this.method === "POST" ? "Posting" : "Redirecting") + " to "+this.url);
				if(this.method === "POST") {
					redirectViaPost(this.url);
				} else {
					redirect(this.url);
				}
			}
		}
	}
</script>
