import wpf from "wpf";

/*** jQuery and UIkit ***/

import jQuery from "jquery";

if(typeof window.jQuery === "undefined") {
	window.jQuery = window.$ = jQuery;
} else if(process.env.NODE_ENV !== "production") {
	console.log("jQuery is already loaded");
}

import trackAjaxErrors from "../playtform-web/components/jquery/trackAjaxErrors"
trackAjaxErrors(wpf, jQuery);

// UIkit exports himself to window
// import UIkit via require to postpone init and avoid "UIkit requires jQuery" error
require("uikit/dist/js/core/core.js");
//require("uikit/dist/js/core/touch.js"); // core/touch.js excluded due to InvalidStateError in IE under Win10 (see PNJ-194)
require("uikit/dist/js/core/utility.js");
//require("uikit/dist/js/core/smooth-scroll.js"); // not used here
//require("uikit/dist/js/core/scrollspy.js"); // not used here
//require("uikit/dist/js/core/toggle.js"); // not used here
require("uikit/dist/js/core/alert.js");
//require("uikit/dist/js/core/button.js"); // not used here
//require("uikit/dist/js/core/dropdown.js"); // not used here
require("uikit/dist/js/core/grid.js");
require("uikit/dist/js/core/modal.js");
//require("uikit/dist/js/core/nav.js"); // not used here
//require("uikit/dist/js/core/offcanvas.js"); // not used here
//require("uikit/dist/js/core/switcher.js"); // not used here
//require("uikit/dist/js/core/tab.js"); // not used here
//require("uikit/dist/js/core/cover.js"); // not used here
// Optional UIkit components
require("uikit/dist/js/components/notify");
//require("uikit/dist/js/components/tooltip"); // not used here
// Patched version of UIkit components
//require("../playtform-web/components/uikit/search"); // not used here
//require("../playtform-web/components/uikit/autocomplete"); // not used here

/*** Main Vue app ***/

import Vue from "vue";

/* Global plugins and mixins */

import "../playtform-web/components/logging";
import "../playtform-web/components/filters";
import "../playtform-web/components/directives";

import SimpleVueValidator from "simple-vue-validator";
import "es6-promise/auto";
Vue.use(SimpleVueValidator);

/* Global Vue components */

import ActionButton from "../playtform-web/components/action-button.vue";
import AlertPanel from "../playtform-web/components/alert-panel.vue";
import { LoginForm, PasswordRestoreForm, PasswordResetForm, SignupForm, ProfileEditForm } from "./components/forms";

const globalComponents = {
	ActionButton, AlertPanel,
	LoginForm, PasswordRestoreForm, PasswordResetForm, SignupForm, ProfileEditForm
};

/* Root Vue mixins and utils */

import AuthControls from "../playtform-web/components/auth-controls"
import hashbangs from "../playtform-web/components/hashbangs";
import cookieConsent from "../playtform-web/components/cookie-consent";

wpf.debug("App prepared");

wpf.init.queue(() => {

	Object.keys(globalComponents).forEach((tag) => {
		Vue.component(tag, Vue.extend(globalComponents[tag]));
	});

	wpf.debug("Staring app");

	wpf.app = new Vue({
		el: "#root",
		mixins: [AuthControls],
		mounted() {
			this.debug("App started");
			cookieConsent();
		}
	});

	Vue.nextTick(() => {
		hashbangs.process();
	});
});

/* Theme-specific code */
require("./theme/fullHeight");
