import SimpleVueValidator from "simple-vue-validator";

export default {
	mixins: [ SimpleVueValidator.mixin ],
	data() {
		return {
			submitted: false,
			valid: false,
			hasErrors: false
		}
	},
	methods: {
		validateAndSubmit(ev) {
			this.submitted = true;
			if(!!ev) {
				ev.preventDefault();
			}
			if(this.valid) {
				this.debug("Submitting validated form ...");
				this.submitForm();
			} else {
				this.$validate()
					.then((success) => {
						if(success) {
							this.debug("Form validated");
							this.valid = true;
							this.submitForm();
						} else {
							this.debug("Form validation failed");
							this.invalidateForm();
						}
					});
			}
			return false;
		},
		submitForm() {
			this.$refs.form.submit();
		},
		invalidateForm() {
			this.submitted = false;
			this.valid = false;
			this.hasErrors = true;
		},
		resetSubmittedForm() {
			this.submitted = false;
			this.valid = false;
			this.hasErrors = false;
		}
	}
}
