import wpf from "wpf";
import { getMsg, jqScrollTo } from "./util";
import notify from "./notify";
import pathToRegexp from "path-to-regexp";

const globalHandlers = [];

const hashbangs = {

	add(path, handler, customHandlers) {
		const keys = [];
		(customHandlers || globalHandlers).push({
			keys: keys,
			regexp: pathToRegexp(path, keys, {}),
			handler: handler
		});
	},

	process(keepHash, customHandlers) {
		let handled = false;
		if(/^#!.*/.test(document.location.hash)) {
			const path = decodeURIComponent(document.location.hash.substr(2));
			if(!!path) {
				wpf.debug("Processing hashbang:", path);
				const handlers = customHandlers || globalHandlers;
				for(let i = 0; !handled && i < handlers.length; i++) {
					const mr = handlers[i].regexp.exec(path);
					if(!!mr) {
						const ctx = {};
						for(let k = 0; k < mr.length; k++) {
							const key = handlers[i].keys[k];
							const val = mr[k+1];
							if(val !== undefined) {
								ctx[key.name] = val;
							}
						}
						wpf.debug(`Running handler for ${path} with context:`, ctx);
						handled = handled || handlers[i].handler(ctx);
					}
				}
				if(handled && !keepHash) {
					if(window.history.replaceState) {
						const woHash = document.location.href.replace(/^([^#]+)#.+$/, "$1").replace(/^([^?]+)\?$/, "$1");
						wpf.debug("Replacing url with:", woHash);
						window.history.replaceState(null, null, woHash); // to prevent jumps after hash change
					} else {
						document.location.hash = "#";
					}
				}
			}
		}
		return handled;
	}
};

hashbangs.add("msg/:msgId", function(ctx) {
	if(!!ctx.msgId) {
		const msg = getMsg(ctx.msgId);
		wpf.debug("Message ["+ctx.msgId+"]: "+msg);
		notify.show(msg, null, null, 0);
	}
	return true;
});

hashbangs.add("err/:errorId", function(ctx) {
	if(!!ctx.errorId) {
		const error = getMsg(ctx.errorId);
		wpf.debug("Error ["+ctx.errorId+"]: "+error);
		notify.error(error);
	}
	return true;
});

hashbangs.add("scroll/:targetId", function(ctx) {
	if(!!UIkit.offcanvas) {
		UIkit.offcanvas.hide(true);
	}
	if(!!ctx.targetId) {
		const target = $("#"+ctx.targetId);
		if(target.length > 0) {
			jqScrollTo(target);
		}
	}
	return true;
});

export default hashbangs;
