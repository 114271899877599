import wpf from "wpf";
import $script from "scriptjs";

const localStorage = wpf.storage.local();
export const udata = localStorage.get("udata", {});

export function saveUdata() {
	localStorage.set("udata", udata);
}

/* AdBlock */

wpf.cfg.abscript = wpf.cfg.abscript || "/assets/js/advert.js";

export const abcheck = {
	done: false,
	detected: false,
	value() {
		return abcheck.done ? (abcheck.detected ? "yes" : "no") : "n/a";
	},
	save() {
		udata.uab = abcheck.value();
		saveUdata();
	}
};

$script(
	wpf.cfg.abscript+"?_="+(new Date().getTime()),
	function() {
		abcheck.done = true;
		abcheck.detected = abcheck.detected || typeof window.TFXrsobCQDLuxr42nN2x === "undefined";
		wpf.debug("Loaded abcheck script: "+abcheck.detected);
		abcheck.save();
	}
);

/* Cashback extensions */

const extChecks = {
	// EPN - https://chrome.google.com/webstore/detail/hfdhpmpfpcnbboppkkkblilhbloejijj
	// unable to check - has no web_accessible_resources
	// Letyshops - https://chrome.google.com/webstore/detail/lphicbbhfmllgmomkkhjfkpbdlncafbn
	"ls": {
		id: "lphicbbhfmllgmomkkhjfkpbdlncafbn",
		file: "images/btn/logo16.png"
	},
	// MegaBonus (ex-AliBonus) - https://chrome.google.com/webstore/detail/dbfipcjecamggjfabeaclacjoohfjhhn
	"mb": {
		id: "dbfipcjecamggjfabeaclacjoohfjhhn",
		opid: "gnmpkjcpdaneoddcilgcoobhkokdbfcd",
		file: "icons/icon32.png"
	},
	// Kopikot - https://chrome.google.com/webstore/detail/iehhgmkphdjocnjjobeoccffiiclcmli
	"kk": {
		id: "iehhgmkphdjocnjjobeoccffiiclcmli",
		opid: "phbjnlefagljbfadbopnkhjfbocipfad",
		file: "img/k1.png"
	},
	// Smarty.Sale - https://chrome.google.com/webstore/detail/jphoeefokmhkfljjhlpbndhikhjpencm
	"ss": {
		id: "jphoeefokmhkfljjhlpbndhikhjpencm",
		opid: "cmmkllggbogaiflfakgfipgemildmhmf",
		file: "img/inject.png"
	},
	// Ebates - https://chrome.google.com/webstore/detail/chhjbpecpncaggjpdakmflnfcopglcmi
	"eb": {
		id: "chhjbpecpncaggjpdakmflnfcopglcmi",
		file: "img/logo16.png"
	}
	// Honey - https://chrome.google.com/webstore/detail/bmnlcjabgnpnenekpadlanbbkooimhnj
};

const useCached = new Date().getTime() - (udata.uextTs || 0) < 7*24*60*60*1000; // Check every 7 days
const cached = {};
(udata.uext || "").split(",").forEach((key) => { if(!!extChecks[key]) { cached[key] = true; } });

export const excheck = {
	done: !window.chrome || wpf.cfg.deviceType !== "normal" || useCached,
	detected: cached || {}, // Always init with cached version as all checks take some time
	detectedList() {
		const list = Object.keys(excheck.detected).filter((key) => excheck.detected[key]);
		list.sort();
		return list.join(",");
	},
	detectedFlag() {
		return !!excheck.detectedList();
	},
	value() {
		return !window.chrome ? "n/c" : wpf.cfg.deviceType !== "normal" ? "n/d" : excheck.detectedList() || "-";
	},
	save() {
		udata.uext = excheck.value();
		udata.uextTs = new Date().getTime();
		saveUdata();
	}
};

if(excheck.done) {
	wpf.debug("Bypassed excheck: "+JSON.stringify(excheck.detected)+" "+excheck.value());
	if(!useCached) {
		excheck.save();
	}

} else {
	const checkOperaExt = /(YaBrowser|OPR)/.test(navigator.userAgent); //Yandex Browser and Opera can use extensions from Opera catalog
	let running = 0;

	let checkExt = function(key, id, file) {
		const callback = function(detected) {
			excheck.detected[key] = detected;
			excheck.save();
			running--;
			excheck.done = running === 0;
			if(excheck.done) {
				wpf.debug("Finished excheck: "+JSON.stringify(excheck.detected)+" "+excheck.value());
			}
		};
		running++;
		let img = document.createElement("img");
		img.onload = function() { callback(true); };
		img.onerror = function() { callback(false); };
		img.src = "chrome-extension://"+id+"/"+file;
	};

	Object.keys(extChecks).forEach((key) => {
		checkExt(key, extChecks[key].id, extChecks[key].file);
		if(checkOperaExt && !!extChecks[key].opid) {
			checkExt(key, extChecks[key].opid, extChecks[key].file);
		}
	});
}
