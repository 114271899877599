import wpf from "wpf";
import SimpleVueValidator from "simple-vue-validator";
import ValidatedForm from "../../playtform-web/components/validated-form"
import { jqToPromise } from "../../playtform-web/components/util";

const Validator = SimpleVueValidator.Validator;

const usernameValidator = {
	debounce: 350,
	validator(v) {
		return Validator.value(v).required().email();
	}
};
const nicknameValidator = {
	debounce: 1000,
	cache: true,
	validator(v) {
		return Validator.value(v).required().minLength(wpf.cfg.nicknameMinLength).custom(() => {
			if(!Validator.isEmpty(v)) {
				return jqToPromise(
					$.ajax({ type: "GET", url: wpf.url.checkNickname(this.nickname, this.userId) })
				).then((data) => {
					return data === "Unavailable" ? "nicknameUnavailable" : null;
				});
			}
		});
	}
};
const passwordValidator = {
	debounce: 350,
	validator(v) {
		const notAllowed = [this.username, this.nickname]; // Must be available on component object
		return Validator.value(v).required().minLength(wpf.cfg.passwordMinLength).notIn(notAllowed);
	}
};
const password2Validator = {
	debounce: 350,
	validator(password2, password) {
		if(this.submitted || this.validation.isTouched("password2")) {
			return Validator.value(password2).required().match(password);
		}
	}
};

export const LoginForm = {
	mixins: [ ValidatedForm ],
	data() {
		return {
			username: "",
			password: ""
		}
	},
	validators: {
		username: usernameValidator,
		password(v) {
			return Validator.value(v).required();
		}
	}
};

export const PasswordRestoreForm = {
	mixins: [ ValidatedForm ],
	data() {
		return {
			username: ""
		}
	},
	validators: {
		username: usernameValidator
	}
};

export const PasswordResetForm = {
	mixins: [ ValidatedForm ],
	props: {
		username: { required: true },
		nickname: { required: true }
	},
	data() {
		return {
			password: "",
			password2: ""
		}
	},
	validators: {
		password: passwordValidator,
		"password2,password": password2Validator
	}
};

export const SignupForm = {
	mixins: [ ValidatedForm ],
	props: {
		prevUsername: { required: true },
		prevNickname: { required: true }
	},
	data() {
		return {
			userId: null, // used in nicknameValidator
			username: this.prevUsername,
			nickname: this.prevNickname,
			password: "",
			password2: ""
		}
	},
	validators: {
		username: usernameValidator,
		nickname: nicknameValidator,
		password: passwordValidator,
		"password2,password": password2Validator
	}
};

export const ProfileEditForm = {
	mixins: [ ValidatedForm ],
	props: {
		userId: { required: true },
		prevUsername: { required: true },
		prevNickname: { required: true }
	},
	data() {
		return {
			username: this.prevUsername,
			nickname: this.prevNickname,
			password: "",
			password2: ""
		}
	},
	validators: {
		username: usernameValidator,
		nickname: nicknameValidator,
		password: passwordValidator,
		"password2,password": password2Validator
	}
};
