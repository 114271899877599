import wpf from "wpf";

// http://stackoverflow.com/a/23304189
export function createRandom(seed) {
	return function() {
		seed = Math.sin(seed) * 10000; return seed - Math.floor(seed);
	};
}
export function isString(obj) {
	return Object.prototype.toString.call(obj) === "[object String]";
}

export function normalizeSpaces(val) {
	return val ? val.replace(/\s+/g, " ").trim() : "";
}

export function leftPad(num, n, str) {
	return new Array(n - String(num).length + 1).join(str||"0") + num;
}

export function addDays(dt, n) {
	let result = new Date(dt);
	result.setDate(dt.getDate() + n);
	return result;
}

export function getMsg(msgId) {
	const msg = wpf.msg[msgId];
	if(!msg) {
		wpf.log("Can't find message with ID = "+msgId);
	}
	return msg ? msg : msgId;
}

export function redirect(url, params) {
	let hash;
	if(url.indexOf("#!") !== -1) {
		hash = url.substr(url.indexOf("#!"));
		url = url.substr(0, url.indexOf("#!"));
	} else {
		hash = params && params.error ? "#!err/" + params.error : (params && params.msg ? "#!msg/" + params.msg : "");
	}

	if(window.location.pathname+window.location.search === url) {
		wpf.debug("Reloading page: "+url+hash);
		if(hash) {
			window.location.hash = hash;
		}
		wpf.forceReload();

	} else {
		wpf.debug("Redirecting to: "+url+hash);
		if(params && params.newTab) {
			window.open(url+hash);
		} else {
			window.location = url+hash;
		}
	}
}

export function redirectViaPost(url, params) {
	wpf.debug("Redirecting via POST to: "+url);
	const form = document.createElement("form");
	form.setAttribute("method", "POST");
	form.setAttribute("action", url);
	for(let key in params) {
		if(params.hasOwnProperty(key)) {
			const fld = document.createElement("input");
			fld.setAttribute("type", "hidden");
			fld.setAttribute("name", key);
			fld.setAttribute("value", params[key]);
			form.appendChild(fld);
		}
	}
	document.body.appendChild(form);
	form.submit();
}

/* Requires jQuery: */

export function jqScrollTo(e, speed) {
	$("html, body").animate({ scrollTop: e.offset().top }, speed != null ? speed : 1000);
}

export function jqScrollToBottom(e, speed) {
	const newpos = e.offset().top + e.height() - $(window).height();
	if(newpos > $(document).scrollTop()) {
		$("html, body").animate({ scrollTop: newpos }, speed != null ? speed : 1000);
	}
}

export function jqScrollToMiddle(e, speed) {
	const newpos = e.offset().top + e.height()/2 - $(window).height()/2;
	$("html, body").animate({ scrollTop: newpos > 0 ? newpos : 0 }, speed != null ? speed : 1000);
}

/* Only 1st param in jQuery Ajax callbacks accessible after converting to ES6 Promise:
   - only data in done(data, textStatus, xhr)
   - and only xhr in fail(xhr, textStatus, error) */
export function jqToPromise(jqDeferred) {
	return new Promise(function(resolve, reject) {
		jqDeferred.then(resolve, reject);
	});
}
