
const _canonicalErrors = {
	400: "Bad Request",
	403: "Forbidden",
	404: "Not Found",
	500: "Internal Server Error",
	502: "Bad Gateway",
	503: "Service Temporarily Unavailable"
};

export default function(wpf, jQuery) {
	jQuery(document).ajaxError(function (ev, xhr, settings) {
		let errMsg = xhr.status+" "+(_canonicalErrors[xhr.status] || xhr.statusText);
		if(xhr.responseJSON && xhr.responseJSON.status) {
			errMsg = errMsg+" / "+xhr.responseJSON.status;
			if(xhr.responseJSON.error) {
				errMsg = errMsg+" "+xhr.responseJSON.error.replace(/^(Can't find task): .+$/, "$1");
			}
		}
		const errLoc = settings.type+" "+settings.url;
		if(xhr.readyState !== 0) {
			if(typeof ga === 'function') { // Only if Google Analytics is available
				ga('send','event','Ajax Error', errMsg, errLoc, { nonInteraction: true });
				wpf.debug("Sent to GA: "+errMsg+" @ "+errLoc);
			} else {
				wpf.debug("Not sent (no GA): "+errMsg+" @ "+errLoc);
			}
		}
	});
}
