import wpf from "wpf";
import { redirect, redirectViaPost } from "./util";

function _doSocialAuth(authUrl) {
	redirectViaPost(authUrl, { lang: wpf.cfg.lang } );
	if(!!UIkit.offcanvas) {
		UIkit.offcanvas.hide(true);
	}
	UIkit.modal.blockUI(wpf.msg.loginInProgress);
}

export default {
	methods: {
		login(provider, ev, confirm) {
			if(ev) { ev.preventDefault(); }
			const authUrl = wpf.url.socialAuth(provider);
			if(confirm) {
				UIkit.modal.confirm(
					wpf.msg.confirmAutoLinkSocialProfile,
					function() { _doSocialAuth(authUrl); },
					{ keyboard: true, modal: true }
				);
			} else {
				_doSocialAuth(authUrl);
			}
		},
		logout(ev) {
			if(ev) { ev.preventDefault(); }
			redirectViaPost( wpf.url.logout(), { lang: wpf.cfg.lang } );
		},
		profile(ev) {
			if(ev) { ev.preventDefault(); }
			redirect(wpf.url.authProfile());
		}
	}
};
