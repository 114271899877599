<template>
	<div :class="'uk-alert uk-alert-'+type" data-uk-alert v-if="msgArray.length > 0">
		<a href="#" class="uk-alert-close uk-close"></a>
		<p v-for="m in msgArray" v-html="m"></p>
	</div>
</template>
<script>
	import { getMsg } from "./util";

	export default {
		props: {
			type: { type: String, required: true },
			messages: { type: String, required: true }
		},
		computed: {
			msgArray() {
				return this.messages.split(",").map(getMsg);
			}
		}
	}
</script>
