import wpf from "wpf";
import { udata, saveUdata } from "./ads/uchecks";

function cookieConsentClosed() {
	udata.ccc = true;
	saveUdata();
}

export default function() {
	wpf.init.queue(() => {
		if(wpf.cfg.gdpr && wpf.lang !== "ru" && wpf.lang !== "uk" && !udata.ccc) {
			wpf.debug("Have to show cookie consent message ...");
			UIkit.notify({
				message: wpf.msg.cookieConsent,
				pos: "bottom-center",
				status: "primary wpf-cookie-consent",
				timeout: 0,
				group: "wpf-cookie-consent",
				onClose: cookieConsentClosed
			})
		}
	});
}
