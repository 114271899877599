"use strict";

(function($) {

	const fn = function() {
		let navHeight = $('.tm-navbar-full').outerHeight();
		let winHeight = $(window).height();
		setTimeout(function(){
			const content = $('.tm-middle');
			if(content.height() + navHeight < winHeight){
				content.css({ 'height': winHeight - navHeight });
				$(document).trigger('display.uk.check');
			}
		}, 100);
	};

	$(window).on("resize", function() {
		fn();
		return fn;
	}());

})(window.jQuery);
